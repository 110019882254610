<template>

    <!-- 从创意开始 弹窗 -->
    <!--       :width="drawerWidth"
      :top="dialogTop" -->
    <el-dialog
      class="custom-dialog custom-dialog-position new-dp-question-dialog"
      ref="dialog"
      :visible.sync="showDialogDpQuestionFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="false"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="true"
      :close-on-click-modal="false"
      :wrapperClosable="true"
      @close="close"
      >
      <div style="padding: 0 1rem 1rem 0;position: relative;">
        <el-row :gutter="10" ref="storyTemplate" style="margin: 0;">
          <!-- 问题 -->
          <el-col :span="24" style="margin-top: 1rem;">
            <div class="d-flex" style="align-items: center;">
              <img src="../../assets/aigen-icon.png" alt="" style="width: 28px; height: 28px;" />
              <span class="question-box-tip">AI灵感助手</span>
              <el-tooltip placement="top">
                <div slot="content">
                  AI 帮你生成问题答案，给你提供灵感
                </div>
                <img class="img-tail" src="../../assets/tishi-icon.png" style=""/>
              </el-tooltip>
            </div>
            <div class="question-box-title">
              <span class="question-box-title-question">问题:</span><span>{{replaceNumber(question.title)}}</span>
            </div>

            <!-- age -->
            <div class="question-box-tag" v-if="question.orderNumber==1">
              <div class="question-box-tag-title">
                时代
              </div>
              <div class="">
                <span @click="handleChecked(item,ageTags)" :class="{'question-box-tag-item-active': item.checked}" class="question-box-tag-item" v-for="(item,index) in ageTags">{{item.label}}</span>
              </div>
            </div>

            <!-- world-view -->
            <div class="question-box-tag" v-if="question.orderNumber==1">
              <div class="question-box-tag-title">
                世界观
              </div>
              <div class="">
                <span @click="handleChecked(item,worldViewTags)" :class="{'question-box-tag-item-active': item.checked}" class="question-box-tag-item" v-for="(item,index) in worldViewTags">{{item.label}}</span>
              </div>
            </div>

            <!-- place -->
            <div class="question-box-tag" v-if="question.orderNumber==1">
              <div class="question-box-tag-title">
                场景
              </div>
              <div class="">
                <span @click="handleChecked(item,placeTags)" :class="{'question-box-tag-item-active': item.checked}" class="question-box-tag-item" v-for="(item,index) in placeTags">{{item.label}}</span>
              </div>
            </div>

            <!-- 生成要求 -->
            <div class="question-box-required">
              <div class="question-box-required-title">
                生成要求
              </div>
              <div class="question-box-required-input">
                <el-input type="textarea" placeholder=" 可以输入自定义的世界设定、场景、情节、人物性格职业等" @click=""
                  v-model="storyContentRequired" class="story-idea no-border-input" clearable :rows="4" maxlength="500" show-word-limit>
                </el-input>
              </div>
            </div>

            <div style="margin: 2rem 0;text-align: center;">
              <el-button :disabled="isSkeleton" v-if="this.storyAnswers.length>0" class="custom-button" style="padding: 10px 55px;" type="default" icon="el-icon-refresh" @click="handleGen(3)">重新生成</el-button>
              <el-button :disabled="isSkeleton" v-else style="padding: 10px 55px;" type="primary" @click="handleGen(2)">开始生成</el-button>
            </div>

            <!-- 答案 -->
            <div class="answer-box" v-if="storyAnswers.length>0 && !isSkeleton">
              <div class="answer-box-title">
                生成答案：
              </div>
              <div class="answer-box-content">
                <div class="answer-box-content-item" v-for="(item, index) in storyAnswers" :key="index">
                  <div class="answer-box-content-item-left">
                    {{item.answer}}
                  </div>
                  <div class="right">
                    <el-button class="custom-button" type="primary" @click="handleChoose(item)" round>选择此答案</el-button>
                  </div>
                </div>
              </div>
              <div class="answer-box-tip">
                * 如没有想要的答案，可重新生成或关闭此弹窗手动输入
              </div>
            </div>
            <LlmSkeleton v-if="isSkeleton" />
          </el-col>
        </el-row>

      </div>

    </el-dialog>
</template>
<script>
import * as StoryTemplateAnswerApi from '@/api/storytemplateanswer'
import * as ArticleApi from '@/api/article'
import { mapState, mapMutations} from 'vuex'
import LlmSkeleton from '@/components/LlmSkeleton'
import sse from '@/utils/llmstream'
import { nextTick } from 'vue'
export default {
  name: 'StoryQuestion',
  props: {
    storyTemplate: {
      type: Object,
      default: {}
    },
    question: {
      type: Object,
      default: {}
    }
  },
  components: {
    LlmSkeleton
  },
  data() {
    return {
      activeItem: {id: '0'},
      isSkeleton: false,
      ageTags: [],
      worldViewTags: [],
      placeTags: [],
      storyAnswers: [],
      storyContentRequired: ''
    }
  },
  mounted() {
    console.log('mounted');
    console.log('storyTemplate=', this.storyTemplate, 'question=', this.question);
    this.handleTemplateTag()
    this.handleTemplateAnswerPage()
  },
  computed: {
    ...mapState(['user', 'story', 'showDialogDpQuestionFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : window.innerWidth <= 1200 ? '80%' : '60%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "4vh" : '10vh';
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogDpQuestionFlag']),
    close(){
      if(this.isSkeleton){
        this.$message.warning('AI正在思考，请耐心等待...');
        return false
      }
      this.$nextTick(()=>{
        this.setShowDialogDpQuestionFlag(false);
      })
    },
    replaceNumber(text){
      // ①②③④⑤⑥⑦⑧⑨⑩
      return text?text.trim().replace('①', '').replace('②', '').replace('③', '').replace('⑩', '')
      .replace('④', '').replace('⑤', '').replace('⑥', '').replace('⑦', '').replace('⑧', '').replace('⑨', ''):'';
    },
    parseAnswer(str) {
      const regex = /\[(.*?)\]/g;
      // 使用 match 方法搜索字符串
      const match = str?str.match(regex):null;
      str = match ? match[1] : '';
      str = str.replace(/\\n/g, '\n').replace(/\n/g, '').replace(/\\/g, '');
      return str
    },
    handleChoose(item){
      console.log('choose ....');
      this.handleTemplateAnswerEnabled(item)
    },
    handleChecked(text, tags){
      tags.forEach((item,index) => {
        if(item == text){
          item.checked = true
        }else{
          item.checked = false
        }
      })
      console.log('ageTags=', this.ageTags, tags);
    },
    findChecked(tags){
      let data = null
      tags.forEach((item,index) => {
        if(item.checked){
          data = item.label;
        }
      })
      console.log('tags=', tags);
      return data
    },
    findQaHistory(){
      let orderNumber = this.question.orderNumber
      let questions = this.$parent.storyTemplateQuestions
      let answers = this.$parent.storyTemplateAnswers
      console.log('findQaHistory ', questions, answers);
      let history = []
      questions.forEach((item,index) => {
        if(item.orderNumber < orderNumber){
          history.push('Q'+(index+1)+':'+this.replaceNumber(item.title))
          history.push('A'+(index+1)+':'+this.replaceNumber(answers[index]))
        }
      })
      console.log('findQaHistory ', history);
      return history.join('\n')
    },
    handleTemplateAnswerPage(){
      if(!this.user.id || !this.story.id){
        return false
      }
      StoryTemplateAnswerApi.page({questionId: this.question.id, aigcFlag: true, accountId: this.user.id, storyId: this.story.id, size: 3}).then(res => {
        let result = res.data.data;
        this.storyAnswers = result.records;
        if(this.storyAnswers.length > 0){
          this.storyContentRequired = this.storyAnswers[0].storyContentRequired
        }
      });
    },
    handleTemplateAnswerEnabled(item){
      StoryTemplateAnswerApi.update({id: item.id, fromType: 'qa_answer_choose', questionId: this.question.id, accountId: this.user.id, storyId: this.story.id}).then(res => {
        // 回调刷新
        this.$parent.storyTemplateAnswers[this.question.orderNumber-1] = item.answer
        console.log('this.$parent.storyTemplateAnswers=', this.$parent.storyTemplateAnswers);
        // nextTick(()=>{
          this.close()
        // })
      });
    },
    handleTemplateTag() {
      // age tag
      if(!this.story.ageTag ||this.story.ageTag.trim() == ''){
        this.story.ageTag = '不限'
      }
      this.storyTemplate.ageTag?this.storyTemplate.ageTag.split(',').forEach((item, index) => {
        this.ageTags.push({
          checked: this.story.ageTag==item,
          label: item
        })
      }):''
      // world view tag
      if(!this.story.worldViewTag ||this.story.worldViewTag.trim() == ''){
        this.story.worldViewTag = '不限'
      }
      this.storyTemplate.worldViewTag?this.storyTemplate.worldViewTag.split(',').forEach((item, index) => {
        this.worldViewTags.push({
          checked: this.story.worldViewTag==item,
          label: item
        })
      }):''
      // place tag
      if(!this.story.placeTag ||this.story.placeTag.trim() == ''){
        this.story.placeTag = '不限'
      }
      this.storyTemplate.placeTag?this.storyTemplate.placeTag.split(',').forEach((item, index) => {
        this.placeTags.push({
          checked: this.story.placeTag==item,
          label: item
        })
      }):''
    },
    handleGen(from){// 2-开始生成 3-重新生成
      console.log('gen .... from=', from);
      this.isSkeleton = true
      if(this.question.orderNumber == 1){
        this.story.ageTag = this.findChecked(this.ageTags)
        this.story.worldViewTag = this.findChecked(this.worldViewTags)
        this.story.placeTag = this.findChecked(this.placeTags)
        this.setStory(this.story)
        if(this.story.id && Number(this.story.id)>0){
          // 存在story
          let params = {
            id: this.story.id,
            ageTag: this.story.ageTag,
            worldViewTag: this.story.worldViewTag,
            placeTag: this.story.placeTag
          }
          console.log('update params=', params);
          // return false
          ArticleApi.update(params).then(res => {

          })
        }
      }

      // return false
      this.genStoryQa()
    },
    removeHtmlTagsAndReplaceBrWithNewline(htmlString) {
      // console.log('html', htmlString)
      // 将 <br> 替换为换行符
      let newlineString = htmlString.replace(/(?=\S)<br\s*\/?>/gi, '');
      const regexP = /<p>(.*?)<\/p>/g;
      newlineString = newlineString.replace(regexP, (match, content) => {
        return `${content}\n`; // 保留内容并在其后追加换行符
      });
      // 去除 HTML 标签
      let noHtmlString = newlineString.replace(/<[^>]*>?/gm, '');
      noHtmlString += "\n 9527"
      console.log('remove html tag', noHtmlString)
      return noHtmlString;
    },
    extractAnswers(answers) {
      let text = this.removeHtmlTagsAndReplaceBrWithNewline(answers?answers:'')
       const regex = /^(?:\s*(\d+)\s*(?:\n(?!\s*\d+\s*$).*)*)(?=(\n\s*\d+\s*$)|\Z)/gm;
       let matches;
       const outlineNumber = [];
       let answersPre = []
       // 使用循环来处理所有匹配的项
       while ((matches = regex.exec(text)) !== null) {
         const number = matches[1];
         // matches[0] 是整个匹配的字符串，包括数字和文本
         const groupWithNumber = matches[0];

         // 提取文本内容，去除数字行
         // const texts = groupWithNumber.split('\n').slice(1); // 从第二行开始，因为第一行是数字
         const texts = groupWithNumber.replace(number, '').trim(); // 从第二行开始，因为第一行是数字
          outlineNumber.push(parseInt(number, 10))
          // 将数字和文本内容添加到结果数组
          answersPre.push(texts);
       }
       console.log('outlineNumber -------------- ' + outlineNumber);
       console.log('answersPre -------------- ' + answersPre);
       return answersPre;
    },
    genStoryQa() {
      this.isSkeleton = true
        let params = {}
        params.articleId = this.story.id
        params.questionTitle = this.replaceNumber(this.question.title)
        params.questionId = this.question.id
        params.storyContentRequired = this.storyContentRequired
        params.storyAgeTag = this.story.ageTag
        params.storyWorldViewTag = this.story.worldViewTag
        params.storyPlaceTag = this.story.placeTag
        params.storyQaHistory = this.findQaHistory()
        console.log('params=', params)
// return false
        sse("1843129344223019009", params, this.handleStoryTitleMsg).then((result)=>{
          console.log('result', result);
          //AI结束，处理逻辑
          // let json = {};
          // try{
          //   json = JSON.parse(result)
          //   if(json.answers.length == 0){
          //     return false
          //   }
          // }catch(e){
          //   this.isSkeleton = false
          //   this.$message.warning('JOSN 解析异常，请重新生成')
          // }
          let temp = result
          result = this.extractAnswers(result)
          if(temp != '' && (!result || result == '' || result.length == 0)){
            this.isSkeleton = false
            // this.$message.warning('抱歉，生成失败，请重试或联系客服人员')
            // this.$message({
            //   dangerouslyUseHTMLString: true,
            //   message: '抱歉，生成失败，请重试或<a style="color: #0052D9;font-weight: bold;" href="https://work.weixin.qq.com/kfid/kfc73d694476dbadaf1" target="_blank">联系客服</a>',
            //   type: 'error',
            //   duration: 8 * 1000
            // })
            this.$message.warning('JOSN 解析异常，请重新生成')
            return
          }
          StoryTemplateAnswerApi.create({
              titles: result,
              templateId: this.question.templateId,
              storyId: this.story.id,
              questionId: this.question.id,
              aigcFlag: true,
              storyContentRequired: this.storyContentRequired,
              fromType: 'qa_answer_titles'})
          .then(res => {
              if(res.data.code == 0){
                let data = res.data.data
                console.log('answer=', data)
                this.storyAnswers = data.answers
              }else{
                this.$message.error(res.data.msg);
              }
              this.isSkeleton = false
          })
        }).catch(error => {
          // 处理错误
          console.error(error);

          this.isSkeleton = false;
        });
    },
    handleStoryTitleMsg(msg){
      // console.log('title', msg);
    }
  },
  beforeDestroy() {

  },
}
</script>

<style scoped lang="scss">
:root {
  --template-rows: 4; /* 默认红色 */
}
$tagMT: .5rem;

.story-idea {
  /deep/ .el-textarea__inner {
      border: none;
      outline: none;
      box-shadow: none;
      background: #FFFFFF !important;
      border-radius: 6px 6px 6px 6px;
      // border: 1px solid #DCDCDC;
  }
  /deep/ .el-textarea__inner:hover,
  /deep/ .el-textarea__inner:focus {
      border-color: transparent;
  }
}

/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .el-dialog__body > div:first-of-type {
  padding: 0 0 .5rem 0 !important;
}

/* 全局滚动条轨道的padding */
.question-box::-webkit-scrollbar-track {
  background-color: transparent;
  margin: 1rem 0;
}
/* 滚动条的样式 */
.question-box::-webkit-scrollbar {
  height: 10px;
  margin: 1rem 0;
  background-color: transparent;
}
.question-box::-webkit-scrollbar-thumb {
  background-color: rgba(54,110,244,0.1);
}
.question-box {
  &-tip {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0,0,0,0.9);
  }
  &-title {
    margin: 2rem 0;
    line-height: 28px;
    &-question {
      color: #366EF4;
      background: #F2F3FF;
      border-radius: 6px 6px 6px 6px;
      padding: .25rem .5rem;
      margin-right: .25rem;
    }
    font-weight: 600;
    font-size: 16px;
    color: rgba(0,0,0,0.9);
  }
  &-tag {
    margin-bottom: 1rem;
    &-title {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0,0,0,0.6);
      margin-bottom: .5rem;
    }
    &-item{
      display: inline-block;
      background: #F2F3FF;
      border-radius: 12px;
      font-weight: 400;
      font-size: 12px;
      color: #242424;
      padding: .25rem 1rem;
      white-space: nowrap;
      margin-right: 1rem;
      margin-bottom: .5rem;
      cursor: pointer;
      &-active {
        color: #366EF4;
      }
    }
  }
  &-required {
    font-weight: 400;
    font-size: 14px;
    &-title{
      color: rgba(0,0,0,0.6);
      margin-bottom: 1rem;
    }
    &-input{
      color: rgba(0,0,0,0.4);
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #DCDCDC;
    }
  }
}
.answer-box {
  background: #F2F3FF;
  border-radius: 12px 12px 12px 12px;
  padding: 1rem;
  &-title{
    font-weight: 600;
    font-size: 16px;
    color: #366EF4;
  }
  &-content > :nth-child(2) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  &-content{
    margin-top: 1rem;
    &-item:hover{
      border: 1px solid #366EF4;
    }
    &-item{
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #FFFFFF;
      padding: .5rem 1rem;
      &-left {
        flex: 1;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        line-height: 22px;
        text-align: justify;
        padding-right: 1rem;
      }
      &-right {
        display: flex;
        align-items: center;
        margin-left: 1rem;
      }
    }
  }
  &-tip{
    font-weight: 400;
    font-size: 12px;
    color: #366EF4;
    margin-top: .5rem;
  }
}
.img-tail {
  width: 18px;
  height: 18px;
  margin-left: .5rem;
}
.custom-dialog {
  /deep/ .el-dialog__headerbtn {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}
</style>
