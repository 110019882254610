<template>
    <!-- 拆书 弹窗 -->
    <el-dialog
      class="custom-dialog new-changpian-dialog"
      ref="dialog"
      :visible.sync="showBreakdownDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="true"
      :withHeader="false"
      :show-close="true"
      :close-on-click-modal="true"
      :wrapperClosable="true"
      :destroy-on-close="true"
      :width="drawerWidth"
      :top="dialogTop"
      @close="close"
      >
      <el-form label-position="top">
        <el-row type="flex" align="middle">
          <h3 style="color: rgba(0,0,0,0.9);">{{dialogTitle}}</h3>
        </el-row>
        <!-- <el-button style="border-radius: 6px !important;padding: 0.5rem 2rem;margin-top: 1rem;margin-bottom: .5rem;" size="small" type="primary" @click="getEnergy()">获取能量</el-button> -->
        <div>
          <el-row type="flex" justify="space-between">
            <el-col :span="24" v-if="enums.ArticleGenTypeEnum && enums.ArticleGenTypeEnum.length>0">
              <el-form-item label="请选择拆书类型" required>
                  <el-radio-group v-model="formData.articleGenType">
                    <el-radio :disabled="item.disabled" :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.ArticleGenTypeEnum.filter((item) => {
                      if(item.code == 3){
                        item.desc = '长篇（暂不支持）'
                        item.disabled = true
                      }else{
                        item.disabled = false
                      }
                      return item.code > 1
                    })">{{item.desc}}</el-radio>
                  </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item prop="url" label="上传作品内容" required>
             <ly-pic-uploader ref="lyuploader" :url="formData.url"></ly-pic-uploader>
          </el-form-item>

          <el-row class="mgt-10" type="flex" justify="space-between">
            <el-col :span="showHeader?24:24" v-if="enums.BookBreakdowTypeEnum && enums.BookBreakdowTypeEnum.length>0">
              <el-form-item label="请选择拆书输出结果（可多选）" required>
                <el-checkbox-group v-model="formData.breakdownTypes">
                  <el-checkbox-button @change="change" class="custom-checkbox" :disabled="item.disabled" :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.BookBreakdowTypeEnum.filter((item) => {
                    return item.code <= 2
                  })">{{item.desc}}</el-checkbox-button>
                </el-checkbox-group>
              </el-form-item>
              <div style="line-height: 1rem !important;margin-top: -.8rem;font-size: 12px;color: rgba(0, 0, 0, .6);">预估消耗能量:<div style="color: #125FDC;padding: 0 .25rem;width: 60px;display: inline-block;text-align: center;">{{ costEnergy }}</div>(仅预估，会有误差)</div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div type="flex" justify="center" style="display: flex;justify-content: center;">
          <el-button :disabled="!btnActiveFlag" style="padding: 10px 55px;" type="primary" @click="newBookBreakdown">开始拆书</el-button>
        </div>
      </div>
    </el-dialog>
</template>
<script>
import { Loading } from 'element-ui'
import { mapState, mapMutations} from 'vuex'
import * as FileApi from '@/api/file'
import LyPicUploader from '@/components/PicUploader/index_drag'
export default {
  name: 'BookBreakdown',
  props: {
    enums: {
      type: Object,
      default: {ArticleTypeEnum: []}
    },
    channel: {
      type: String,
      default: 'bookbreakdown_index'
    },
  },
  components: {
    LyPicUploader
  },
  data() {
    return {
      formData: {
        articleGenType: 'GEN_BY_ZHIHU',
        fromType: 'breakdown',
        targetType: 'BOOK_BREAKDOWN',
        breakdownTypes: [],
        url: ''
      },
      costEnergy: '-',
      rules: {
        url: [
          { required: true, message: '请选择文件', trigger: 'blur' }
        ],
      }
    }
  },
  mounted() {
    console.log('breakdown dialog mounted');
    window.addEventListener('resize', this.forceUpdate);
  },
  computed: {
    ...mapState(['user', 'story', 'showBreakdownDialogFlag']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '98vw' : window.innerWidth <= 1200 ? '45%' : '45%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "4vh" : '10vh';
    },
    dialogTitle () {
      return '新建拆书'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
    btnActiveFlag() {
      return (this.formData.articleGenType && this.formData.articleGenType.length>0)
      && (this.formData.breakdownTypes && this.formData.breakdownTypes.length>0)
      && (this.formData.url && this.formData.url.length>0)
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowBreakdownDialogFlag']),
    close(){
      this.resetData()
      this.setShowBreakdownDialogFlag(false);
    },
    change(event){
      console.log('change', event);
      this.getEnergy()
    },
    newBookBreakdown() {
      if (!this.formData.articleGenType || !this.formData.articleGenType.trim()) {
          this.$message.error('请选择故事类型');
          return false;
      }
      if (!this.formData.breakdownTypes || this.formData.breakdownTypes.length == 0) {
          this.$message.error('请选择拆书输出结果');
          return false;
      }
      // const file = this.$refs.lyuploader.getFile()
      // this.formData.url = file.url
      // this.formData.name = file.name
      this.getFile()
      if (!this.formData.url || this.formData.url.length == 0) {
          this.$message.error('请上传作品');
          return false;
      }
      this.$confirm('拆书预计需要 3～10 分钟，是否确认开始？', '提示', {
        confirmButtonText: '确认开始',
        cancelButtonText: '取消',
        customClass: 'custom-message-box',
        center: false
      }).then(() => {
        let params = Object.assign({}, this.formData)
        this.close()
        let loading = Loading.service({
          lock: true,
          text: '加载中...',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        FileApi.create(params).then(res => {
           console.log('create file', res);
           this.$parent.handlePage(1);
           setTimeout(()=>{loading.close()}, 300)
        });

      }).catch((e) => {console.log('error', e)});
    },
    resetData(){
      this.formData.breakdownTypes = []
      this.formData.url = ''
    },
    getFile(){
      const file = this.$refs.lyuploader.getFile()
      console.log('getFile = ', file);
      // this.formData.url = file.url
      // this.formData.name = file.name
      this.$set(this.formData, 'url', file.url);
      this.$set(this.formData, 'name', file.name);
    },
    removeFile(){
      console.log('removeFile = ', file);
      // this.formData.url = ''
      // this.formData.name = ''
      this.$set(this.formData, 'url', '');
      this.$set(this.formData, 'name', '');
    },
    getEnergy() {
      const file = this.$refs.lyuploader.getFile()
      console.log('file: ', file);

      if (!file.url || file.url.length == 0) {
          // this.$message.error('请上传作品');
          this.costEnergy = '-'
          return false;
      }
      if(this.formData.breakdownTypes.length == 0){
        this.costEnergy = '-'
        return false;
      }
      FileApi.create({url: file.url, fromType: 'energy', breakdownTypes: this.formData.breakdownTypes }).then(res => {
         console.log('get energy', res);
         const result = res.data.data;
         this.costEnergy = result.energy
         // this.$parent.updateEnergy()
      });
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: normal;
}
.custom-checkbox {
  margin-right: 1rem !important;
  margin-bottom: .5rem !important;
  border: none !important;
  ::v-deep .el-checkbox-button__inner {
    border: none !important;
    background: #F2F3FF;
    border-radius: 20px;
    padding: 6px 30px;
    color: #242424;
    font-size: 14px;
    font-weight: 400;
  }

}
::v-deep .el-checkbox-button:first-child .el-checkbox-button__inner {
  border-radius: 20px;
}

::v-deep .el-checkbox-button:last-child .el-checkbox-button__inner {
  border-radius: 20px;
}
::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  background-color: #366EF4 !important;
  border-color: #366EF4 !important;
  box-shadow: none;
}
::v-deep .el-form-item__label {
  color: rgba(0, 0, 0, .8);
}
</style>
<style>
.el-message-box.custom-message-box {
  border-radius: 20px !important;
  padding: .5rem;
  padding-bottom: 1rem;
}
.el-button {
  border-radius: 6px !important;
}
</style>
