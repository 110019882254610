<template>
<!-- contact -->
<!-- <VueDragResize contentClass="content-box" :isActive="true"  :isResizable="false" :parentLimitation="true"> -->
<!-- <div class="contact-box">
  <div class="contact-box-show" v-if="showFlag">
    <img class="contact-box-show-icon" src="https://maliwriter.com/static/qrcode-contact-1.jpg" alt="" />
    <div class="contact-box-show-tip" v-if="isWeixin && isMobile">
      微信长按识别二维码咨询
    </div>
    <div class="contact-box-show-tip" v-if="isMobile">
      下载图片微信扫码咨询
    </div>
    <div class="contact-box-show-tip" v-if="!isMobile">
      微信扫码咨询
    </div>
  </div>
  <div class="contact-box-item" @click.stop="showContent()">
    <img class="contact-box-item-icon" src="../assets/contact-kefu.png" alt="" />
  </div>
</div> -->

<div class="contact-box">
  <el-dropdown trigger="click" placement="left-start">
    <span class="el-dropdown-link">
      <div class="contact-box-item">
        <img class="contact-box-item-icon" src="../assets/contact-kefu.png" alt="" />
      </div>
    </span>
    <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
      <el-dropdown-item>
        <div class="contact-box-show">
          <img class="contact-box-show-icon" src="https://maliwriter.com/static/qrcode-contact-1.jpg" alt="" />
          <div class="contact-box-show-tip" v-if="isWeixin && isMobile">
            微信长按识别二维码咨询
          </div>
          <div class="contact-box-show-tip" v-if="isMobile">
            下载图片微信扫码咨询
          </div>
          <div class="contact-box-show-tip" v-if="!isMobile">
            微信扫码咨询
          </div>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</div>

<!-- </VueDragResize> -->
<!-- contact-end -->
</template>
<script>
import VueDragResize from 'vue-drag-resize';
export default {
  name: 'CusImage',
  components: {
    VueDragResize
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showFlag: false
    }
  },
  computed: {
      isMobile(){
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag
      },
      isWeixin(){
        let flag = navigator.userAgent.match(/(MicroMessenger)/i)
        return flag
      },
  },
  methods: {
    showContent(){
      this.showFlag = !this.showFlag
    }
  }
}
</script>
<style scoped lang="scss">
.content-box {
  position: fixed;
  top: 50vh;
  right: 0;
  z-index: 9009 !important;
  width: 4.1rem;
  height: 4.1rem;
}
// .custom-dropdown-menu {
  ul.custom-dropdown-menu {
    background: transparent;
    border: none;
    box-shadow: none;
    li {
      line-height: 12px;
    }
    li:hover {
      background: transparent;
    }
    ::v-deep .popper__arrow {
      display: none !important;
    }
    ::v-deep .el-dropdown-menu__item{
      padding-left: 40px;
      padding-right: 0px;
      margin-top: -10px;
    }
  }
// }

// PC
@media screen and (min-width: 768px) {
// contact
.contact-box {
  position: fixed;
  top: 70vh;
  right: 0;
  z-index: 9009;
  display: flex;
  &-show {
    margin-right: 1rem;
    font-size: 0.7rem;
    text-align: center;
    padding: 5px;
    background: #FFFFFF;
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.08), 0px 16px 24px 2px rgba(0,0,0,0.04), 0px 6px 30px 5px rgba(0,0,0,0.05);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #DCDCDC;
    &-icon {
      width: 8rem;
    }
    &-tip {
      color: rgba(0, 0, 0, 1);
    }
  }
  &-item {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color: rgba(255,255,255,0.98);
    &-icon {
      width: 4rem;
    }
    &-icon:hover {
      width: 4.1rem;
    }
    &-tip {

    }
  }
}

}

// mobile
@media screen and (max-width: 767px) {
// contact
.contact-box {
  position: fixed;
  top: 70vh;
  right: 0;
  z-index: 9009;
  display: flex;
  &-show {
    margin-right: 1rem;
    font-size: 0.7rem;
    text-align: center;
    background: #FFFFFF;
    box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.08), 0px 16px 24px 2px rgba(0,0,0,0.04), 0px 6px 30px 5px rgba(0,0,0,0.05);
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #DCDCDC;
    padding: 5px;
    &-icon {
      width: 8rem;
    }
    &-tip {
      color: rgba(0, 0, 0, 1);
    }
  }
  &-item {
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    color: rgba(255,255,255,0.98);
    &-icon {
      width: 4rem;
      width: 4rem;
    }
    &-icon:hover {
      width: 4.1rem;
    }
    &-tip {

    }
  }
}
}
//contact end
</style>
