<template>

  <el-container class="layout" style="background: #242424;">

    <el-header>
      <HeadNavWriter @get-hide-flag="getHideFlag" />
    </el-header>
    <!-- <div class="" style="height: 3rem;" v-if="showMaskFlag">

    </div> -->
    <el-container class="main">

      <el-aside class="writer-bg hidden-xs-only">
        <!-- <el-scrollbar  style="background: #242424;"> -->
          <el-menu style="border: 0;color: #fff;margin-top: 1rem;"
            active-color="#0252D9" background-color="#242424"
            :default-active="activeMenuItem"
            class="el-menu-agent"
            @select="handleSelect"
            @open="handleOpen"
            @close="handleClose">
            <el-menu-item index="1" :class="{'is-active': activeMenuItem == '1'}">
              <img style="width: 1rem;margin-right: 1rem;" src="@/assets/menu-edit.png"/>
              <span slot="title" style="color: #fff;">创作空间</span>
            </el-menu-item>
            <el-menu-item index="2" :disabled='false' :class="{'is-active': activeMenuItem == '2'}">
              <img style="width: 1rem;margin-right: 1rem;" src="@/assets/menu-chaishu.png"/>
              <span slot="title" style="color: #fff;">拆文学习</span>
            </el-menu-item>
            <el-menu-item index="3" :disabled='false' :class="{'is-active': activeMenuItem == '3'}">
              <img style="width: 1rem;margin-right: 1rem;" src="@/assets/menu-shuomingshu.png"/>
              <span slot="title" style="color: #fff;">创作指南</span>
            </el-menu-item>
            <el-menu-item index="4" :disabled='true'>
              <img style="width: 1rem;margin-right: 1rem;" src="@/assets/menu-lib.png"/>
              <span slot="title" style="color: #fff;">素材宝库(敬请期待)</span>
            </el-menu-item>
            <el-menu-item index="5" :disabled='true'>
              <img style="width: 1rem;margin-right: 1rem;" src="@/assets/menu-tips.png"/>
              <span slot="title" style="color: #fff;">灵感源泉(敬请期待)</span>
            </el-menu-item>
          </el-menu>
        <!-- </el-scrollbar> -->
      </el-aside>

      <el-main style="z-index: 1;" class="dialog-container ">
        <!-- <el-card class="box-card" :body-style="{ padding: '0px', height: '100%' }"> -->
          <router-view  class=""/>
        <!-- </el-card> -->
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import { mapState, mapMutations} from 'vuex'
export default {
  name: 'Article',
  components: {
    HeadNavWriter
  },
  data() {
    return {
      sliderBoxActive: 0,
      showMaskFlag: true
    }
  },
  mounted() {
    let currPath = this.$router.history.current.path
    console.log('当前页面 currPath：', currPath);
    this.$nextTick(() => {
      let currentPath = localStorage.getItem('currentPath')
      if(currentPath && currentPath != '/index'){
        // if(currentPath == '/bookbreakdown/index'){
        //   this.setActiveMenuItem('2')
        // }
        this.$router.push(currentPath)
      }else{
        // this.setActiveMenuItem('1')
        this.$router.push('/home')
      }
    })
  },
  computed: {
    ...mapState(['activeMenuItem'])
  },
  methods: {
    ...mapMutations(['setActiveMenuItem']),
    getHideFlag(flag){
      console.log('getHideFlag', flag);
      this.showMaskFlag = flag
    },
    handleSelect(key, keyPath) {
      this.setActiveMenuItem(key+'')
      console.log(key, keyPath, this.activeMenuItem);
      if(key == 1){
        // this.setActiveMenuItem(key+'')
        this.$router.push('/home')
      }else if(key == 2){
        // this.setActiveMenuItem(key+'')
        this.$router.push('/bookbreakdown/index')
      }else if(key == 3){
        let url = 'https://qcnx120ai68z.feishu.cn/wiki/D1Qkw0Kqoic66MkvL0NcAw8gnqg?from=from_copylink'
        window.open(url, '_blank')
        // document.location.href=url
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  }
}
</script>

<style scoped lang="scss">
.layout {
  height: 100%;
}
.main {
  min-height: 1px;
  flex: 1;
  margin-top: .8rem;
}
.el-aside {
  padding: 0px;
  margin-bottom: 0 !important;
  height: 100%;
  width: 17rem !important;
}
.el-scrollbar {
  height: 100%;
  background-color: #ffffff;
}
.el-header {
  padding: 0px !important;
  background-color: #001A40;
}
.el-main {
  // padding: 0 !important;
  // margin: 0 !important;
  height: 100%;
  overflow: hidden; /* 禁止父容器滚动 */
}
.dialog-container {
  background: #FAFAFD;
  border-radius: 20px;
  height: calc( 100% - 1rem );
  margin: 0 1rem 1rem;
  margin-bottom: 1rem;
  padding:1rem;
  overflow: auto;
}
/* 全局滚动条轨道的padding */
.dialog-container::-webkit-scrollbar-track {
  background-color: transparent;
  // padding: 20px 0; /* 上下padding */
  margin: 1rem 0;
}

/* 滚动条的样式 */
.dialog-container::-webkit-scrollbar {
  width: 8px;
  margin: 1rem 0;
  background-color: transparent;
}

.dialog-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.1);
  // border-radius: 6px;
  // background-color: transparent;
}

.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
/deep/ .el-card {
    // border: 0;
    overflow-y: hidden !important;
}
// .writer-home-card-box
.writer-home-card-box{
  // display: flex;
  // margin: 1rem 0 ;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}
.writer-home-card {
  flex: 1;
  cursor: pointer;
  // border: 1px solid red;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
  border-radius: 14px;
  box-sizing: border-box; /* 边框计算在宽度内 */
  &-title-en {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    line-height: 22px;
  }
  &-title {
    font-weight: 600;
    font-size: 28px;
    color: rgba(0,0,0,0.9);
    margin: 1rem 0;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    &-img {
      width: 7rem;
      margin-top:  -.7rem;
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    &-img {
      width: 1rem;
    }
  }
  &-up {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.4);
    line-height: 22px;
    display: flex;
    align-items: center;
    &-icon {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #2BA471;
      line-height: 1rem;
      margin-right: .5rem;
      border-radius: 3px;
      padding: .25rem .5rem;
      &-img {
        width: 1rem;
      }
    }
  }
}
.writer-home-card-box > :nth-child(1) {
  background-image: url('../../assets/writer-edit2.png'), linear-gradient( 151deg, rgba(125,174,255,0.6) 0%, rgba(148,255,197,0.3) 50%, rgba(172,195,255,0.1) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  // background-image: ;
}
.writer-home-card-box > :nth-child(2) {
  background-image: url('../../assets/writer-light2.png'), linear-gradient( 137deg, rgba(137,150,255,0.6) 0%, rgba(235,210,255,0.6) 45%, rgba(144,126,255,0.04) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.writer-home-card-box > :nth-child(3) {
  background: linear-gradient( 180deg, rgba(130,158,255,0) 0%, rgba(239,243,255,0.99) 100%);
}

// .writer-story-card

// .writer-story-card-box .writer-story-card:nth-child(4n+1) {
//   margin-left: 0 !important;
// }
.writer-story-card-box {
  width: 100%;
  min-height: 400px;
  // display: flex;
  // flex-flow: row wrap;
  // align-content: flex-start;
  // justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  grid-auto-rows: 10px;
  margin-bottom: 10px;
}
.writer-story-card {
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box; /* 边框计算在宽度内 */
  border-radius: 6px;
  grid-row-end: span 6;
  &-title {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(0,0,0,0.9);
    height: 3rem;
    line-height: 1.5rem;
  }
  &-time {
    font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    line-height: 22px;
    &-label {
      margin-right: .5rem;
    }
  }
  &-menu {
    &-delete {
      // padding: .5rem;
      img {
        margin-right: .5rem;
      }
      // background: #F2F3FF;
      border-radius: 3px;
      color: #0052D9;
      font-size: .8rem;
    }
  }
}
/* 假设你的菜单类名为 .my-menu */
/deep/ .el-menu-agent .el-menu-item {
  border-radius: 6px !important;
  margin: 0 1rem 6px;
  height: 2.5rem;
  line-height: 2.5rem;
}
/deep/ .el-menu-agent .el-menu-item:hover {
  background-color: #4B4B4B !important;
}
.el-menu-agent .el-menu-item.is-active {
  background-color: #0252D9 !important; /* 激活状态下的背景颜色 */
}
/deep/ .writer-story-card-menu-delete .el-dropdown-item {
  background-color: transparent !important;
}
/deep/ .writer-story-card-menu-delete .el-dropdown-item:hover {
  background-color: transparent !important;
}

@media screen and (min-width: 500px) {
  .writer-home-card-box {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  /deep/ .custom-dialog .el-dialog {
    width: 60%;
  }
}
</style>
