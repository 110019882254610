<template>
<div>
    <div class="news-box" :class="{'news-box-pc': !isMobile, 'news-box-mobile': isMobile}">
      <div class="news-box-last" :class="{'news-box-last-pc': !isMobile, 'news-box-last-mobile': isMobile}">
        <div class="news-box-last-bgimg">
          <span class="news-box-last-title">最新动态</span>
          <div class="news-box-last-content" style="display: inline-block;">
            <el-carousel style="height: 20px;line-height: 20px;width: 100%;text-wrap: nowrap;" direction="vertical" :autoplay="true" :loop="true" indicator-position="none">
              <el-carousel-item v-for="(item, index) in dongtai" :key="index">
                <a :title="item.title" style="text-wrap: nowrap;text-overflow: ellipsis;">{{ item.title }}</a>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="news-box-contribute">
        <span><img src="../../assets/tougao-icon.png" style="width: auto;height: 3rem;" /></span>
        <span class="news-box-contribute-title">投稿</span>
        <!-- <span class="news-box-contribute-content">言情/千字20元/10000-15000字</span> -->
        <div class="news-box-contribute-content" style="display: inline-block;flex: 1;min-width: 20rem;">
          <el-carousel style="height: 20px;line-height: 20px;width: 100%;" :interval="5000" direction="vertical" :autoplay="true" :loop="true" indicator-position="none">
            <el-carousel-item v-for="(item, index) in tougao" :key="index">
              <a :title="item.title" style="text-wrap: nowrap;text-overflow: ellipsis;" target="_blank" :href="item.linkUrl">{{ item.title }}</a>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <el-row>
      <div class="writer-home-card-box">
        <!-- 从创意开始 -->
        <el-card shadow="hover" class="writer-home-card" @click.native="handleGen('genbyidea')">
              <div class="writer-home-card-top">
                <div class="">
                  <div class="writer-home-card-title"><span style="color: #0052D9;">超短篇小说</span></div>
                  <div class="writer-home-card-title-en" style="background: #366EF4;">
                    创建超短篇小说
                  </div>
                </div>
              </div>
              <div class="writer-home-card-bottom">
                <div class="writer-home-card-up">
                  <span class="writer-home-card-up-icon" style="background: #E3F9E9;"><img src="@/assets/shangsheng1.png" class="writer-home-card-up-icon-img" />90%</span>
                  <span style="color: #366EF4;">适合LOFTER，社区发帖型创作，约 5000 字左右</span>
                </div>
              </div>
        </el-card>
        <!-- 知乎短篇 -->
        <el-card shadow="hover" class="writer-home-card" @click.native="handleGen('genbyzhihu')">
          <div class="writer-home-card-top">
            <div class="">
              <div class="writer-home-card-title"><span style="color: #4043FF;">短篇小说</span></div>
              <div class="writer-home-card-title-en" style="background: #6366FF;">
                创建短篇故事
              </div>
            </div>
          </div>
          <div class="writer-home-card-bottom">
            <div class="writer-home-card-up">
              <span class="writer-home-card-up-icon" style="background: rgba(0,82,217,0.26);color: #366EF4;"><img src="@/assets/shangsheng2.png" class="writer-home-card-up-icon-img" />50%</span>
              <span style="color: #6366FF;">适合知乎盐选，网易短篇等，约 1.3 万字左右</span>
            </div>
          </div>
        </el-card>
        <!-- 知乎体短篇 -->
        <el-card shadow="hover" class="writer-home-card" @click.native="handleGen('genbychangpian')">
          <div class="writer-home-card-top">
            <div class="">
              <div class="writer-home-card-title"><span style="color: #FD733B;">长篇小说</span></div>

              <div class="writer-home-card-title-en" style="background: #FF8A5A;">
                创建长篇故事
              </div>
            </div>
          </div>
          <div class="writer-home-card-bottom">
            <div class="writer-home-card-up" style="">
              <span class="writer-home-card-up-icon" style="background: linear-gradient( 180deg, rgb(255, 196, 172, .3) 0%, rgba(253, 115, 59, .3) 100%);color: #FD733B;"><img src="@/assets/shangsheng3.png" class="writer-home-card-up-icon-img" />80%</span>
              <span style="color: #FF8A5A;">适合传统连载网文平台，如番茄，七猫等，约 2000 字/章</span>
            </div>
          </div>
        </el-card>
      </div>
    </el-row>

    <div style="font-weight: 400;font-size: 1rem;color: rgba(0,0,0,0.5);line-height: 22px;margin: 1.8rem 0 0.8rem;">
      <span>最近打开</span>
    </div>
    <div class="d-flex flex-between flex-y-center" style="border-radius: 20px 0px 0px 0px;width: 100%;">
      <div class="d-flex flex-between flex-y-center" style="width:100%;">
        <div class="d-flex">
          <div class="ly-story-gen-type pointer" :style="{'background': genTypeActive==item.name?'#FFFFFF':'linear-gradient( 270deg, #F2F3FF 0%, #DEE4FF 100%)','color': genTypeActive==item.name?'#366EF4':'#366EF4', 'font-weight': genTypeActive==item.name?'600':''}"
           style="padding: .6rem 1.2rem .5rem 1.2rem;
            border-radius: 12px 12px 0px 0px;font-size: .8rem;"
                v-for="(item,index) in enums.ArticleGenTypeEnum"
                @click.stop="handlePage(1,item)"
                :key="index" >
                    {{item.desc+'小说'}}
          </div>
        </div>
        <div class="">
          <span style="margin-left: 0.5rem;" v-if="showStateFlag">
            <el-select style="width: 6rem;" class="custom-select"
                v-model="queryForm.articleState"
                filterable
                size="mini"
                placeholder="完成状态">
              <el-option
                v-for="(item,index) in enums.ArticleStateEnum"
                @click.native.stop="handlePage()"
                :label="item.desc"
                :key="index"
                :value="item.name"
              />
            </el-select>
          </span>
          <span style="display: inline-block;margin-left: 0.5rem; ">
            <el-input suffix-icon="el-icon-search" class="search-title custom-select" v-model="queryForm.title" @input="handlePage()" placeholder="输入名称搜索" clearable>
            </el-input>
          </span>
        </div>
      </div>
    </div>
    <div class="writer-story-card-box" v-if="data.length>0" style="position: relative;">
      <el-card shadow="hover" class="writer-story-card" style="position: relative;"
         v-for="(item,index) in data" :key="index"
          @click.native.stop="itemClick(item)">
            <div class="word-count-tail" :class="{'word-count-tail-dp': item.articleGenType == 'GEN_BY_ZHIHU','word-count-tail-cdp': item.articleGenType == 'GEN_BY_IDEA' || item.articleGenType == 'GEN_BY_OUTLINE','word-count-tail-cp': item.articleGenType == 'GEN_BY_CHANGPIAN'}">
              {{ getTailByGenType(item) }}
            </div>
            <div class="writer-story-card-title-header d-flex" style="justify-content: space-between;margin-top: 1rem;">
              <div class="ellipsis2 writer-story-card-title d-flex-1">
                <!-- <span>【{{item.articleTypeLabel}}】</span> -->
                <span v-if="item.title">{{ item.title }}</span>
                <span v-else style="color: #999;">该故事还没有名字</span>
              </div>
            </div>
            <div class="d-flex" style="margin-top: .5rem;">
              <div style="background: #F1F2FF;border-radius: 20px;font-size: 12px;color: #366EF4;padding: .25rem .5rem;">{{item.articleGenTypeLabel}}</div>
              <div style="margin-left:.5rem;background: #F1F2FF;border-radius: 20px;font-size: 12px;color: #366EF4;padding: .25rem .5rem;">{{item.articleTypeLabel}}</div>
              <div style="margin-left:.5rem;background: #F1F2FF;border-radius: 20px;font-size: 12px;padding: .25rem .5rem;" v-if="item.articleGenType!='GEN_BY_CHANGPIAN'" :style="{'color': item.articleState=='COMPLETED'?'#366EF4':'#A6A3A3'}">{{item.articleStateLabel}}</div>
            </div>
            <div class="" style="display: flex;align-items: center;justify-content: space-between;margin-top: .5rem;">
              <div class="writer-story-card-time">
                <span class="writer-story-card-time-label">最近编辑</span>
                <span>{{ $time.formatTime(new Date(item.updateTime).getTime()) }}</span>
              </div>
              <div class="writer-story-card-menu">
                <el-dropdown @click.native.stop :trigger="isMobile ? 'click':'hover'" >
                  <span><img style="width: 1rem;" src="@/assets/writer-more.png" /></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>
                      <div class="writer-story-card-menu-delete d-flex flex-y-center pointer" @click.stop="handleRemove(item)">
                        <img style="width: .8rem;" src="@/assets/writer-delete.png"/>
                        <span>删除</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
      </el-card>
    </div>
    <el-empty class="data-empty" v-else image="https://www.creatimix.com/static/empty.jpg" description="还没有创作记录，快开启加速之旅吧！" style="margin: 0 auto;background-color: #FFFFFF;">
      <p style="margin: 0;color: rgba(0,0,0,0.6);font-size: 14px;">Start your journey!</p>
    </el-empty>

    <el-row>
      <el-col>
        <div class="pagination">
          <el-pagination background
            hide-on-single-page
            :page-sizes="paginations.pages"
            :page-size="paginations.size"
            :layout="paginations.layout"
            :total="paginations.total"
            :current-page.sync="paginations.current"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          />
        </div>
      </el-col>
    </el-row>

    <!-- 大纲生文 弹窗 -->
    <el-dialog :append-to-body="true" class="custom-dialog"
      title="你想要以此生成什么新故事？"
      :visible.sync="dialogShowGenbyoutline">

      <el-row :gutter="10">
        <el-col :span="24" class="my-1">
          <span class="field-title">故事类型</span><span class="field-required">*</span>
        </el-col>
        <el-col :span="24">
          <el-select
              v-model="story.articleType"
              filterable
              size="mini"
              placeholder="类型">
            <el-option
              v-for="(item,index) in [enums.ArticleTypeEnum[0]]"
              @click.native.stop="contentChange(item)"
              :label="item.desc"
              :key="index"
              :value="item.name"
            />
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="10" class="my-1">
        <el-col :span="24" class="my-1">
          <span class="field-title">写作视角</span><span class="field-required">*</span>
        </el-col>
        <el-col :span="24">
          <el-radio-group v-model="story.writingPerspective">
            <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.WritingPerspectiveEnum">{{item.desc}}</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>

      <el-row :gutter="10" type="flex" justify="center" style="margin-top: 2rem; margin-bottom: 1rem;">
        <el-button class="" type="primary" @click="newStoryByOutline">开 始</el-button>
      </el-row>
    </el-dialog>

    <StoryIdeaDialog v-if="showDialogFlag" :enums="enums" channel="workspace_index" />
    <!-- <StoryIdeaDialogDp v-if="showDialogDpFlag" :enums="enums" channel="workspace_index" /> -->
    <Contact />
  </div>
</template>
<script>
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as EvaluationApi from '@/api/evaluation'
import * as CultureApi from '@/api/culture'
import * as StoryCpApi from '@/api/storycp'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import StoryIdeaDialog from '@/components/StoryIdeaDialog'
// import StoryIdeaDialogDp from '@/components/StoryIdeaDialog/index_dp'
import Contact from '@/components/Contact'
export default {
  name: 'Article',
  components: {
    StoryIdeaDialog,Contact
  },
  data() {
    return {
      genTypeActive: '-1',
      fromType: 'writer_client',
      dialogShowGenbyoutline: false,
      showStateFlag: true,
      dialogShowGenbyidea: false,
      enums: {"ArticleTypeEnum":[{"desc":"","name":""}]},
    	targetPermit: 'article',
      queryForm: {
        name: '',
        fromType: 'writer-web',
        articleGenType: '-1',
        articleState: '',
        current: '',
        size: 20
      },
      activeMenuItem: 1,
      synesthesiaElements:[],
      cps:[],
      hots:[],
      data: [],
      dongtai: [],
      tougao: [],
      paginations: {
        current: 1, // 当前页
        total: 0, // 总行数
        size: 20, // 每页行数
        // sizes: [20], // 每页行数 调整
        layout: 'total, prev, pager, next' // 翻页属性
      }
    }
  },
  mounted() {
    this.$nextTick(() => {

    })
    this.handlePage()
    this.handleEnum()
    this.setStoryContents([])
    this.handleMessage()
  },
  computed: {
  	...mapState(['user', 'story', 'showDialogFlag', 'showDialogDpFlag']),
    isMobile() {
      return window.innerWidth <= 768 ? true : false;
    }
  },
  methods: {
    ...mapMutations(['setStory','setStoryContents', 'setShowDialogFlag', 'setShowDialogDpFlag', 'setShowChangpianDialogFlag']),
    contentChange(item){
      console.log('contentChange', item);
    },
    getTailByGenType(item){
      let tail = ''
      if(item.articleGenType == 'GEN_BY_ZHIHU'){
        tail = '短篇小说'
      }else if(item.articleGenType == 'GEN_BY_IDEA' || item.articleGenType == 'GEN_BY_OUTLINE'){
        tail = '超短篇小说'
      }else if(item.articleGenType == 'GEN_BY_CHANGPIAN'){
        tail = '长篇小说'
      }else{
        tail = ''
      }
      return tail;
    },
    handleGen(cmditem) {
      if (!cmditem) {
        this.$message.error('菜单选项缺少command属性')
        return
      }
      let story = {id: ''}
      switch (cmditem) {
        case 'genbyidea'://cdp
          // gen story
          // this.dialogShowGenbyidea = true
          story.articleGenType = 'GEN_BY_IDEA'
          story.articleType = 'TANBI_DOUBLE_BOY'
          this.setStory(story)
          this.setShowDialogFlag(true)
          break
        case 'genbyoutline':
          // gen story
          story.articleGenType = 'GEN_BY_OUTLINE'
          story.articleType = 'TANBI_DOUBLE_BOY'
          this.setStory(story)
          this.dialogShowGenbyoutline = true
          break
        case 'genbyzhihu'://dp
          story.articleGenType = 'GEN_BY_ZHIHU'
          story.articleType = 'FEEL_GOOD'
          this.setStory(story)
          this.$router.push({ path: '/workspace/gen'})
          break
        case 'genbychangpian'://cp
          story.articleGenType = 'GEN_BY_CHANGPIAN'
          story.articleType = 'CP_YANQING'
          this.setStory(story)
          this.setShowChangpianDialogFlag(true)
          this.$router.push({ path: '/workspace/gencp'})
          break
      }
    },
    newStoryByOutline() {
      if (!this.story.articleType || !this.story.articleType.trim()) {
          this.$message.error('请选择故事类型');
          return false;
      }

      if (!this.story.writingPerspective || !this.story.writingPerspective.trim()) {
          this.$message.error('请选择写作视角');
          return false;
      }
      // return false;
      ArticleApi.create(this.story).then(res => {
          if(res.data.code == 0){
            let data = res.data.data
            this.setStory(data)
            console.log('story', this.story);
            this.$router.push('/workspace/genbyoutline');
          }else{
            this.$message.error(res.data.msg);
          }
      })
    },
    handleRemove(item) {
      this.$confirm('', '确定删除吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(() => {
        ArticleApi.remove(item.id).then(res => {
          this.handlePage();
        });
      }).catch((e) => {console.log('error', e)});
    },
    itemClick(item){
      this.setStory(item)
      setTimeout(()=>{
        if(!item.stepNumber || item.stepNumber == 0 || item.stepNumber == 1){
          if(this.story.articleGenType == 'GEN_BY_ZHIHU'){
              this.$router.push('/workspace/gen')
          }else if(this.story.articleGenType == 'GEN_BY_CHANGPIAN'){
            this.$router.push('/workspace/gencp')
          }else{
            this.$router.push('/workspace/genbyoutline')
          }
        }else {
          if(this.story.articleGenType == 'GEN_BY_ZHIHU'){
            this.$router.push('/workspace/gen' + (item.stepNumber-1))
          }else if(this.story.articleGenType == 'GEN_BY_CHANGPIAN'){
            this.$router.push('/workspace/gencp')
          }else{
            this.$router.push('/workspace/genbyoutline' + item.stepNumber)
          }
        }
      }, 300)
    },
    handleEnum() {
      let that = this;
      EnumApi.list().then(res => {
        let result = res.data.data;
        result.ArticleStateEnum.unshift({name: '-1', desc: '全部'})
        result.ArticleGenTypeEnum.shift()
        result.ArticleGenTypeEnum.unshift({name: '-1', desc: '全部'})
        that.enums = result
      })
    },
    handlePage(current, item) {

      let that = this;
      if (current) {
        that.paginations.current = current
      }
      that.queryForm.current = that.paginations.current
      that.queryForm.size = that.paginations.size

      console.log('workspace index user', this.user);
      if(!this.user || !this.user.id){
        return false;
      }
      if(item){
        that.queryForm.articleGenType = item.name
        this.genTypeActive = item.name
      }
      if(this.queryForm.articleGenType == 'GEN_BY_CHANGPIAN'){
        that.showStateFlag = false
        that.queryForm.articleState = ''
      }else{
        that.showStateFlag = true
      }
      that.queryForm.accountId = this.user.id

      let params = Object.assign({},that.queryForm)
      if(this.queryForm.articleState == '-1'){
        params.articleState = ''
      }
      if(this.queryForm.articleGenType == '-1'){
        params.articleGenType = ''
      }
      // 获取表格数据
      ArticleApi.page(params).then(res => {
        let result = res.data.data;
        that.data = result.records
        that.paginations.total = Number(result.total)
        that.paginations.current = Number(result.current)
        that.paginations.size = Number(result.size)
      })
    },
    handleMessage() {
      CultureApi.page({size: 100}).then(res => {
        let result = res.data.data;
        this.dongtai = result.records
      })
      EvaluationApi.page({size: 100}).then(res => {
        let result = res.data.data;
        this.tougao = result.records
      })
    },
    handleCurrentChange(page) {
      // 切换页码
      this.paginations.current = page
      this.handlePage()
    },
    handleSizeChange(size) {
      // 切换size
      this.paginations.size = size
      this.size = size
      this.handlePage()
    }
  }
}
</script>

<style scoped lang="scss">
.layout {
  height: 100%;
}
.main {
  min-height: 1px;
  flex: 1;
}
.el-aside {
  padding: 0px;
  margin-bottom: 0 !important;
  height: 100%;
  width: 17rem !important;
}
.el-scrollbar {
  height: 100%;
  background-color: #ffffff;
}
.el-header {
  padding: 0px !important;
  background-color: #001A40;
}
.el-main {
  // padding: 0 !important;
  // margin: 0 !important;
  height: 100%;
  overflow: hidden; /* 禁止父容器滚动 */
}
.dialog-container {
  background: #F4F5F9;
  border-radius: 20px;
  height: calc( 100% - 1rem );
  margin: 0 1rem 1rem;
  margin-bottom: 1rem;
  padding:1rem;
  overflow: auto;
}
.el-drawer.rtl {
    right: 0;
    overflow-y: auto !important;
}
/deep/ .el-card {
    // border: 0;
    overflow-y: hidden !important;
}
/deep/ .el-empty__image img {
  margin-top: 20px;
}
/deep/ .custom-select .el-input__inner {
    // border: none; /* 去除边框 */
    // outline: none; /* 去除聚焦时的轮廓 */
    // box-shadow: none; /* 去除阴影 */
    // background: transparent;
    border-radius: 20px;
    // height: 22px;
    // line-height: 22px;
}

/deep/ .el-carousel__item {
  white-space: nowrap;
  overflow: hidden;        /* 隐藏超出元素盒子的内容 */
  text-overflow: ellipsis; /* 显示省略号(...) */
}
.search-title {
  /deep/ .el-input__inner {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
  }
  /deep/ .el-input__suffix {
    height: 1.75rem;
    line-height: 1.75rem;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
}
.el-input__inner .el-input__inner:hover {
    /deep/ {
      // border-color: transparent; /* 边框颜色设置为透明 */
      // border: none;
      // outline: none;
    }
}
// .writer-home-card-box
.ly-story-gen-type:hover {
  color: #366EF4 !important;
}

.writer-home-card-box{
  // display: flex;
  // margin: 1rem 0 ;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
}
.writer-home-card {
  flex: 1;
  cursor: pointer;
  // border: 1px solid red;
  box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.05);
  border-radius: 14px;
  box-sizing: border-box; /* 边框计算在宽度内 */
  &-title-en {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255,255,255,.8);
    line-height: 22px;
    border-radius: 20px;
    display: inline-block;
    padding: .1rem 1rem;
  }
  &-title {
    font-weight: 600;
    font-size: 28px;
    color: rgba(0,0,0,0.9);
    margin: 1rem 0;
  }
  &-top {
    display: flex;
    justify-content: space-between;
    &-img {
      width: 7rem;
      margin-top:  -.7rem;
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &-img {
      width: 1rem;
    }
  }
  &-up {
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.4);
    line-height: 22px;
    display: flex;
    align-items: flex-start; /* 顶部对齐 */
    &-icon {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #2BA471;
      line-height: 1rem;
      margin-right: .5rem;
      border-radius: 3px;
      padding: .25rem .5rem;
      &-img {
        width: 1rem;
      }
    }
  }
}
.writer-home-card-box > :nth-child(1) {
  background-image: url('../../assets/writer-edit2.png'), linear-gradient( 151deg, rgba(125,174,255,0.6) 0%, rgba(148,255,197,0.3) 50%, rgba(172,195,255,0.1) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
  // background-image: ;
}
.writer-home-card-box > :nth-child(2) {
  background-image: url('../../assets/writer-light2.png'), linear-gradient( 137deg, rgba(137,150,255,0.6) 0%, rgba(235,210,255,0.6) 45%, rgba(144,126,255,0.04) 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.writer-home-card-box > :nth-child(3) {
  background-image: url('../../assets/writer-changpian.png'), linear-gradient( 314deg, #FFECA8 0%, #FFFEDE 50%, #FFD4AC 100%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right top;
}
.word-count-tail {
  border-radius: 6px 0px 20px 0px;
  width: auto; /* 默认值，但明确设置有助于理解 */
  white-space: nowrap; /* 防止文本换行 */
  display: inline-block;
  padding: .25rem 1.5rem .25rem 1rem;
  // font-weight: 600;
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  &-cdp{
    background: linear-gradient( 90deg, #DAE1FF 0%, #F2F3FE 100%);
    color: #0052D9;
  }
  &-dp{
    background: linear-gradient( 90deg, #DAE1FF 0%, #F6F2FE 100%);
    color: #4043FF;
  }
  &-cp{
    background: linear-gradient( 90deg, #FFE9BC 0%, #FFF7DC 100%);
    color: #FD733B;
  }
}
.data-empty {
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
  border-radius: 0px 20px 20px 20px;
  padding: 1rem;
}
/deep/ .el-empty {
  min-height: 30vh !important;
}
/deep/ .el-empty__bottom {
  margin-top: 10px;
}


.writer-story-card-box {
  width: 100%;
  min-height: 400px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.1);
  // background: #F4F5F9;
  border-radius: 0px 20px 20px 20px;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  grid-auto-rows: 10px;
  margin-bottom: 10px;
}
.writer-story-card {
  cursor: pointer;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box; /* 边框计算在宽度内 */
  border-radius: 6px;
  grid-row-end: span 7;
  &-title {
    font-weight: 600;
    font-size: 1rem;
    color: rgba(0,0,0,0.9);
    height: 3rem;
    line-height: 1.5rem;
  }
  &-time {
    // font-weight: 400;
    font-size: 12px;
    color: rgba(0,0,0,0.3);
    line-height: 22px;
    &-label {
      margin-right: .5rem;
    }
  }
  &-menu {
    &-delete {
      // padding: .5rem;
      img {
        margin-right: .5rem;
      }
      // background: #F2F3FF;
      border-radius: 3px;
      color: #0052D9;
      font-size: .8rem;
    }
  }
}
/* 假设你的菜单类名为 .my-menu */
/deep/ .el-menu-agent .el-menu-item {
  border-radius: 6px !important;
  margin: 0 1rem 6px;
  height: 2.5rem;
  line-height: 2.5rem;
}
/deep/ .el-menu-agent .el-menu-item:hover {
  background-color: #4B4B4B !important;
}
.el-menu-agent .el-menu-item.is-active {
  background-color: #0252D9 !important; /* 激活状态下的背景颜色 */
}
/deep/ .writer-story-card-menu-delete .el-dropdown-item {
  background-color: transparent !important;
}
/deep/ .writer-story-card-menu-delete .el-dropdown-item:hover {
  background-color: transparent !important;
}

@media screen and (min-width: 500px) {
  .writer-home-card-box {
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
  /deep/ .custom-dialog .el-dialog {
    width: 60%;
  }
}
.news-box {
  display: flex;
  margin-bottom: 1rem;
  &-pc{
    justify-content: space-between;
  }
  &-mobile{
    flex-direction: column;
  }
  &-last {
    background: linear-gradient( 90deg, #E5E7FC 0%, #F1F2FD 100%);
    border-radius: 14px 14px 14px 14px;
    &-pc{
      flex: 1;
      margin-right: 1rem;
    }
    &-mobile{
      margin-bottom: 1rem;
    }
    &-bgimg {
      background-image: url('../../assets/icon-zxdt.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: left top;
      display: flex;
      padding: 1rem;
    }
    &-title {
      color: #366EF4;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      font-style: italic;
    }
    &-content {
      margin-left: 1rem;
      color: #242424;
      flex: 1;
      font-size: 14px;
    }
  }
  &-contribute {
    background: linear-gradient( 91deg, #E5E7FC 0%, rgba(234,239,255,0.45) 96%);
    border-radius: 14px 14px 14px 14px;
    display: flex;
    align-items: center;
    padding: 0 1rem 0 .25rem;
    &-title {
      color: #366EF4;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      text-wrap: nowrap;
    }
    &-content {
      margin-left: 1rem;
      color: #366EF4;
      font-size: 14px;
    }
  }
}
</style>
