import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const types = {
  SET_IS_AUTNENTIATED: 'SET_IS_AUTNENTIATED', // 是否认证通过
  SET_TOKEN: 'SET_TOKEN', // 用户信息
  SET_USER: 'SET_USER', // 用户信息
  SET_STORY: 'SET_STORY', // 用户信息
  SET_ROLE: 'SET_ROLE'// 用户角色
}
let debug = false
const state = { // 需要维护的状态
  isAutnenticated: false, // 是否认证
  dialogShowCostomContactFlag: false, // 联系客服
  dialogShowRechargeFlag: false, // 充值
  dialogShowNewerGiftFlag: false, // 大礼包
  showDialogFlag: false, // 是否认证
  showDialogDpFlag: false, // 是否认证
  showDialogDpQuestionFlag: false, // 是否认证
  showChangpianDialogFlag: false, //
  showBreakdownDialogFlag: false, //
  showBreakdownDetailDialogFlag: false,
  showLoginDialogFlag: false, //
  dialogShowPaySuccessFlag: false,
  showVideoFlag: false,
  hideFlagML: false,
  hideFlagZW: false,
  hideFlagMLZW: false,
  showMenuFlag: false,
  user: {id: ''}, //
  story: {id: ''}, //
  activeQuestion: {}, //
  activeTemplate: {id: '-1'},
  storyContents: [], //
  role: [],
  activeMenuItem: '1',
}

const mutations = {
  [types.SET_IS_AUTNENTIATED](state, isAutnenticated) {
    if (isAutnenticated) { state.isAutnenticated = isAutnenticated } else { state.isAutnenticated = false }
  },
  [types.SET_TOKEN](state, token) {
    if (token) { state.token = token } else { state.token = '' }
  },
  [types.SET_USER](state, user) {
    if (user) { state.user = user } else { state.user = {} }
  },
  [types.SET_ROLE](state, role) {
    state.role = role
  },
  setActiveMenuItem(state, activeMenuItem) {
    state.activeMenuItem = activeMenuItem
  },
  setActiveQuestion(state, activeQuestion) {
    state.activeQuestion = activeQuestion
  },
  setActiveTemplate(state, activeTemplate) {
    state.activeTemplate = activeTemplate
  },
  setStory(state, story) {
    if (story) { state.story = story } else { state.story = { id: ''} }
  },
  setStoryContents(state, storyContents) {
    state.storyContents = Array.from(storyContents);
  },
  setShowDialogFlag(state, showDialogFlag) {
    state.showDialogFlag = showDialogFlag;
  },
  setShowDialogDpFlag(state, showDialogDpFlag) {
    state.showDialogDpFlag = showDialogDpFlag;
  },
  setShowDialogDpQuestionFlag(state, showDialogDpQuestionFlag) {
    state.showDialogDpQuestionFlag = showDialogDpQuestionFlag;
  },
  setShowChangpianDialogFlag(state, showChangpianDialogFlag) {
    state.showChangpianDialogFlag = showChangpianDialogFlag;
  },
  setShowBreakdownDialogFlag(state, showBreakdownDialogFlag) {
    state.showBreakdownDialogFlag = showBreakdownDialogFlag;
  },
  setShowBreakdownDetailDialogFlag(state, showBreakdownDetailDialogFlag) {
    state.showBreakdownDetailDialogFlag = showBreakdownDetailDialogFlag;
  },
  setDialogShowCostomContactFlag(state, dialogShowCostomContactFlag) {
    state.dialogShowCostomContactFlag = dialogShowCostomContactFlag;
  },
  setDialogShowRechargeFlag(state, dialogShowRechargeFlag) {
    state.dialogShowRechargeFlag = dialogShowRechargeFlag;
  },
  setDialogShowNewerGiftFlag(state, dialogShowNewerGiftFlag) {
    state.dialogShowNewerGiftFlag = dialogShowNewerGiftFlag;
  },
  setDialogShowPaySuccessFlag(state, dialogShowPaySuccessFlag) {
    state.dialogShowPaySuccessFlag = dialogShowPaySuccessFlag;
  },
  setShowLoginDialogFlag(state, showLoginDialogFlag) {
    state.showLoginDialogFlag = showLoginDialogFlag;
  },
  setShowMenuFlag(state, showMenuFlag) {
    state.showMenuFlag = showMenuFlag;
  },
  setShowVideoFlag(state, showVideoFlag) {
    state.showVideoFlag = showVideoFlag;
  }
}

const actions = {
  // 设置角色
  setRole: ({ commit }, role) => {
    if(debug) console.log(commit, role);
    commit(types.SET_ROLE, role)
  },
  // 设置认证
  setIsAutnenticated: ({ commit }, isAutnenticated) => {
    if(debug) console.log(commit, isAutnenticated);
    commit(types.SET_IS_AUTNENTIATED, isAutnenticated)
  },
  // 设置用户
  setUser: ({ commit }, user) => {
    if(debug) console.log(commit, user);
    commit(types.SET_USER, user)
  },
  // 设置故事
  setStory: ({ commit }, story) => {
    if(debug) console.log(commit, story);
    commit('setStory', story)
  },
  // 设置故事
  setStoryContents: ({ commit }, storyContents) => {
    if(debug) console.log(commit, storyContents);
    commit('setStoryContents', storyContents)
  },
  // 设置 Token
  setToken: ({ commit }, token) => {
    if(debug) console.log(commit, token);
    commit(types.SET_TOKEN, token)
  },
  // 设置 setShowDialogFlag
  setShowDialogFlag: ({ commit }, showDialogFlag) => {
    if(debug) console.log(commit, showDialogFlag);
    commit("setShowDialogFlag", showDialogFlag)
  },
  // 设置 dialogShowCostomContactFlag
  setDialogShowCostomContactFlag: ({ commit }, dialogShowCostomContactFlag) => {
    if(debug) console.log(commit, dialogShowCostomContactFlag);
    commit("setDialogShowCostomContactFlag", dialogShowCostomContactFlag)
  },
  setDialogShowRechargeFlag: ({ commit }, dialogShowRechargeFlag) => {
    if(debug) console.log(commit, dialogShowRechargeFlag);
    commit("setDialogShowRechargeFlag", dialogShowRechargeFlag)
  },
  setShowVideoFlag: ({ commit }, showVideoFlag) => {
    if(debug) console.log(commit, showVideoFlag);
    commit("setShowVideoFlag", showVideoFlag)
  },
  setActiveMenuItem: ({ commit }, activeMenuItem) => {
    if(debug) console.log(commit, activeMenuItem);
    commit("setActiveMenuItem", activeMenuItem)
  },
  clearCurrentState: ({ commit }) => {
    if(debug) console.log(commit, 'clear');
    commit(types.SET_IS_AUTNENTIATED, false)
    commit(types.SET_TOKEN, null)
    commit(types.SET_ROLE, [])
    commit(types.SET_USER, null)
    commit('setStory', null)
  }
}

export default new Vuex.Store({
  plugins: [createLogger()],
  state,
  // getters,
  mutations,
  actions
})
