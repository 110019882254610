<template>
  <div class="writer-box writer-bg">
    <HeadNavWriter ref="headeNvWriterRef"/>

    <div class="writer-box-content">

      <!-- steps -->
      <StepsWriter :active="0"/>

      <!-- elements -->
      <div class="hidden-xs-only" style="font-weight: 600; font-size: 20px; color: #000000; line-height: 24px;margin: 1rem 0;">
        故事要素
      </div>
      <div class="writer-box-content-editor">
<!-- @cut.native="changeInput"
         @paste.native="changeInput" -->
        <el-input
         @input="changeInput"
         v-if="!isSkeleton" class="writer-box-input" id="msgbox" type="textarea" :disabled="!storyElement" v-model="storyElement" maxlength="3000" show-word-limit></el-input>
        <LlmSkeleton v-else />
      </div>

    </div>

    <!-- footer -->
    <div class="writer-box-footer" :class="{'button-flex-between': story.articleGenType == 'GEN_BY_IDEA'}">
      <span v-show="story.articleGenType == 'GEN_BY_IDEA' && storyElements.length==0"></span>
      <span v-show="story.articleGenType == 'GEN_BY_IDEA' && storyElements.length>0">
        <!-- <el-checkbox style="margin-right: .5rem;" v-model="checked4ElementVersion" @change="changeElementVersion"></el-checkbox> -->
        <el-select class="custom-select"
            v-model="story.storyElementId"
            filterable
            size="mini"
            :disabled="isLoading"
            placeholder="版本">
          <el-option
            v-for="itemVersion in storyElements"
            @click.native.stop="contentChange(itemVersion)"
            :key="itemVersion.id"
            :label="'版本 ' + itemVersion.version"
            :value="itemVersion.id"
          />
        </el-select>
      </span>
      <span class="button-group">
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" round @click="genStoryElement()" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_IDEA' && storyElements.length==0">生成故事要素</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="default" round icon="el-icon-refresh" @click="handleSave(1)" :loading="isLoading" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_IDEA' && storyElements.length>0">重新生成</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(2)" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_IDEA' && storyElements.length>0">生成大纲</el-button>
        <el-button class="custom-button" :size="$root.buttonSize" type="primary" @click="handleSave(0)" :disabled="isLoading" v-if="story.articleGenType == 'GEN_BY_OUTLINE' && storyElements.length>0">下一步</el-button>
      </span>
    </div>
    <!-- <el-button @click="genStoryTitle()">genStoryTitle</el-button> -->
    <StoryIdeaDialog :enums="enums" channel="genbyoutline_regenelement" />
  </div>
</template>
<script>
import HeadNavWriter from '@/components/HeadNavWriter'
import StepsWriter from '@/components/StepsWriter'
import LlmSkeleton from '@/components/LlmSkeleton'
import * as EnumApi from '@/api/enums'
import * as ArticleApi from '@/api/article'
import * as StoryElementApi from '@/api/storyelement'
import * as StoryOutlineApi from '@/api/storyoutline'
import { mapState, mapMutations} from 'vuex'
import StoryIdeaDialog from '@/components/StoryIdeaDialog'
import sse from '@/utils/llmstream'
export default {
  name: 'Article',
  components: {
    LlmSkeleton, HeadNavWriter, StepsWriter, StoryIdeaDialog
  },
  data() {
    return {
      checked4ElementVersion: false,
      enums: {},
      isLoading: false,
      isSkeleton: false,
    	targetPermit: 'article',
      storyElement: '',
      storyElements: [{id: '0', content: '', version: 1}],
      storyElementTemplate: `### 故事背景：

### 世界观：

### 故事主线：

### 情感线：

### 人设：

`
    }
  },
  computed: {
  	...mapState(['user', 'story', 'dialogShowCostomContactFlag'])
  },
  mounted() {
    this.handlePageStoryElementVersion()
    this.handleEnum()
  },
  methods: {
    ...mapMutations(['setStory', 'setShowDialogFlag', 'setDialogShowCostomContactFlag']),
    contentChange(item){
      // console.log('contentChange', item.content);
      this.storyElement = item.content
      this.story.storyElement = item.content
      this.story.storyElementId = item.id
      this.setStory(this.story)
      this.handleUpdateStory(item)
    },
    changeInput(event){
      // console.log('changeInput', event);
      this.handleUpdateElement()
    },
    replaceLineBreaks(str) {
      return str?str.replace(/\\n/g, '\n').replace(/\n/g, '<br>'):"";
    },
    handleUpdateStory(item) {
      ArticleApi.update({id: this.story.id,
          storyElementId: item.id,
          storyElement: item.content}
      ).then(res => {

      });
    },
    handleUpdateElement() {
      if(this.isLoading){// 正在生成内容
        return false;
      }
      if(!this.story.storyElementId || this.story.storyElementId == 0){
        return false
      }
      StoryElementApi.update({id: this.story.storyElementId,
          content: this.storyElement}
      ).then(res => {
          this.story.storyElement = this.storyElement
          this.setStory(this.story)
          // 更新this.storyElements = [];
          this.storyElements.forEach((item,index)=>{
            if(this.story.storyElementId == item.id){
              item.content = this.storyElement
            }
          })
      });
    },
    handlePageStoryElementVersion(flag){
      StoryElementApi.page({storyId: this.story.id, size: 100}).then(res => {
        let result = res.data.data;
        if(res.data.code == 0){
          if(result.records.length > 0){
            this.storyElements = result.records;
            let currentElement = this.storyElements.filter(i=>{return i.id==this.story.storyElementId})[0];
            if(currentElement){
              this.storyElement = currentElement.content
              this.story.storyElementId = currentElement.id
            }else{
              this.story.storyElementId = this.storyElements[result.records.length-1].id
              this.storyElement = this.storyElements[result.records.length-1].content
            }
          }else{
            // this.story.storyElementId = '0'
            // this.storyElements = [{id: '0', content: '', version: 1}];
            this.storyElements = [];
          }
          if ((!this.storyElement || !this.storyElement.trim()) && this.story.articleGenType == 'GEN_BY_OUTLINE' ) {
              this.storyElement = this.storyElementTemplate;
          }
          this.story.storyElement = this.storyElement
          this.setStory(this.story)
          console.log('handlePageStoryElementVersion', this.story);
          if(result.records.length == 0 && this.story.articleGenType == 'GEN_BY_IDEA'){// 从创意开始，自动生成要素
            if(this.user.desktopFlag){
              this.genStoryElement()
            }
          }
          if(flag && flag == 3 && this.story.articleGenType == 'GEN_BY_IDEA'){// 自动生成标题
            this.genStoryTitle()
            this.contentChange(this.storyElements[this.storyElements.length-1])
          }
        }
      })
    },
    handleEnum() {
      EnumApi.list().then(res => {
        let result = res.data.data;
        this.enums = result
      })
    },
    handleViewStory() {
      ArticleApi.view(this.story.id).then(res => {
        if(res.data.code == 0){
          console.log('handleViewStory', res.data.data)
          this.setStory(res.data.data)
        }
      })
    },
    handleSave(flag) {
      if(flag == 0){//by outline 下一步
        if (!this.storyElement || !this.storyElement.trim() || this.storyElement == this.storyElementTemplate) {
            this.$message.error('请输入故事要素');
            return false;
        }
        StoryElementApi.create({storyId: this.story.id, content: this.storyElement}).then(res => {
            this.story.storyElement = this.storyElement
            console.log('handleSave')
            this.story.stepNumber = 2
            this.setStory(this.story)
            this.genStoryTitle()
            this.$router.push('/workspace/genbyoutline2');
        })
      }else if(flag == 1){// by idea : 重新生成 弹窗
        // this.story.reCreate = true
        // this.setStory(this.story)
        this.setShowDialogFlag(true)
      }else if(flag == 2){// by idea : 下一步
        if (!this.storyElement || !this.storyElement.trim() || this.storyElement == this.storyElementTemplate) {
            this.$message.error('请输入故事要素');
            return false;
        }
        console.log('handleSave flag 2')
        if(this.story.stepNumber < 2){
          this.story.stepNumber = 2
          this.setStory(this.story)
        }
        setTimeout(()=>{
          this.$router.push('/workspace/genbyoutline2');
        },300)
      }else if(flag == 3){//by idea AI 生成完之后保存版本
        this.handlePageStoryElementVersion(3)
      }
    },
    scrollToBottom() {
      const msgbox = document.getElementById("msgbox")
      msgbox.scrollTop = msgbox.scrollHeight;
    },
    handleStoryTitleMsg(msg){

    },
    genStoryTitle() {
        console.log('story::title', this.story);
        if (this.story.title) {
            return false;
        }
        if (!this.story.storyElement) {
            // this.$message.error('故事要素丢失');
            return false;
        }
        let params = Object.assign({}, this.story)
        params.articleId = this.story.id
        params.storyElement = this.story.storyElement
        params.platformCategory = 'WRITER_WEB'
        sse("1806516321777786881", params, this.handleStoryTitleMsg).then((result)=>{
          // console.log('result', result);
          //AI结束，处理逻辑
          this.story.title = result.replace(/<[^>]*>?/gm, '');
          this.setStory(this.story)
          this.$refs.headeNvWriterRef.syncUser()
        })
    },
    handleStoryElementMsg(msg){
      // console.log('element', msg);
      this.isSkeleton = false;
      this.storyElement = msg
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
    genStoryElement() {
        console.log('story::element', this.story);
        this.isLoading = true
        this.isSkeleton = true
        this.storyElement = ''
        let params = Object.assign({}, this.story)
        params.articleId = this.story.id
        params.content = this.story.storyIdea

        let workflowId = "1791021434835726337"
        // sse
        sse(workflowId, params, this.handleStoryElementMsg).then((result)=>{
          // console.log('genStoryElement sse', result);
          //AI结束，处理逻辑
          this.isLoading = false;
          this.isSkeleton = false;
          this.storyElement = result
          this.story.storyElement = result
          this.setStory(this.story)
          this.$refs.headeNvWriterRef.syncUser()
          // 保存版本
          this.handleSave(3)
        }).catch(error => {
          // 处理错误
          console.error(error);
          let currentElement = this.storyElements.filter(i=>{return i.id==this.story.storyElementId})[0];
          if(currentElement){
            this.storyElement = currentElement.content
          }
          this.isLoading = false;
          this.isSkeleton = false;
        })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
